import Cookies from 'js-cookie';
import actions from './actions';

const { CHANGE_ID, SET_COMPANIES } = actions;

const initState = {
  id: parseInt(localStorage.getItem('companyId') ? localStorage.getItem('companyId') : '0'),
};

/**
 *
 * @todo impure state mutation/explaination
 */
const CurrentCompanyReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CHANGE_ID:
      return {
        ...state,
        id: data.id,
      };
    default:
      return state;
  }
};
export default CurrentCompanyReducer;
