import React, { useEffect, useState } from 'react';
import { Avatar, Select } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import { useTranslation } from 'react-i18next';
import { changeRtlMode, changeLayoutMode, changeMenuMode } from '../../../redux/themeLayout/actionCreator';
import Cookies from 'js-cookie';
import { ChangeCompanyId } from '../../../redux/currentCompany/actionCreator';
const AuthInfo = () => {


  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const user = JSON.parse(localStorage.getItem('user'));
  const history = useHistory();
  const [state, setState] = useState({
    flag: i18n.language == 'en' ? 'en' : 'he',
  });
  const { flag } = state;

  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut());
  };
  const [companies, setCompanies] = useState(JSON.parse(localStorage.getItem('companies')));
  const { currentCompanyId, roles } = useSelector(state => {
    return {
      currentCompanyId: state.currentCompany?.id,
      roles: state.auth.roles,
    };
  });
  useEffect(() => {
    if (currentCompanyId == 0 && companies.length > 0) {
      dispatch(ChangeCompanyId({ id: companies[0].id }))
    }
  }, [])
  const { Option } = Select;


  function handleCompanyChange(value) {
    dispatch(ChangeCompanyId({ id: value }))
    var role = roles.find(x => x.companyId === value)?.role;
    if (role == 2 || role == 3) {
      history.push('/admin/forms')
    } else if (role == 4 || role == 5 || role == 6) {
      history.push('/admin/attendance')
    }
  }


  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" height="46" width="46" alt="" />
          <figcaption>
            <Heading as="h5">{user?.name}</Heading>
            <p>{user?.email}</p>
          </figcaption>
        </figure>

        <br />

        {t('Company')}
        <Select dropdownStyle={{ zIndex: 99999 }} value={companies.length > 0 ? currentCompanyId : ''} onChange={(v) => handleCompanyChange(v)} style={{ width: '100%' }}>
          {companies?.map((x) => <Option value={x.id}>{x.name}</Option>)}
        </Select>

        <a className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" />{t('Logout')}
        </a>
        {/* <ul className="user-dropdwon__links">
          <li>
            <Link to="#">
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li>
        </ul> */}

      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = value => {
    i18n.changeLanguage(value);
    if (value == 'en') {
      dispatch(changeRtlMode(false));
    } else {
      dispatch(changeRtlMode(true));
    }
    setState({
      ...state,
      flag: value,
    });
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle('en')} to="#">
        <img src={require('../../../static/img/flag/en.png')} alt="" />
        <span>{t('en')}</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('he')} to="#">
        <img src={require('../../../static/img/flag/he.png')} alt="" />
        <span>{t('he')}</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Message />
      <Notification />

      <Settings />
      <Support /> */}
      <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div>

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
