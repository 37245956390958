/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API = {
  auth: {
    login: 'Authentication/login',
    signUp: 'Authentication/register',
    passwordForget: 'Authentication/passwordForget',
  },
  newForm:{
    pdfUpload: '/form/upload',
    postForm: '/form/form',
  },
  editForm:{
    getForm: '/form/getFormById',
    postForm: '/form/editForm',
  },
  fillForm:{
    getForm: '/form/getForm',
    postValues:'/form/postValues',
    saveValues:'/form/saveValues',
  },
  forms:{
    getForms: '/form',
    deactiveForm: '/form',
    deleteForm:'/form',
    getDeleted: '/form/GetDeleted',
    restoreForm: '/form/Restore',
    restoreAllForm: '/form/RestoreAll',
    deleteAllForm: '/form/DeleteAll',
    sendEmail: '/form/SendEmail',
  },
  client:{
    post: '/client',
    get:'/client/all',
    delete:'/client',
    getForm:'/client/getForm',
  },
  company:{
    getAll: '/company/all',
    getActive: '/company/allactive',
    getUserCompanies:'/company/getUserCompanies',
    post: '/company',
    put: '/company',
    delete: '/company',
    deactive: '/company/deactive',
    postEmailTemplate: '/company/PostEmailTemplate',
  },
  user:{
    getAll: '/user/all',
    post: '/user',
    put: '/user',
    delete:'/user',
    resetPass: '/user/ResetPassword'
  },
  note:{
    get:'/note',
    post:'/note',
    delete:'/note',
    put:'/note',
    changeLabel:'/note/ChangeLabel',
    changeFavorite:'note/ChangeFavorite',
    changeIndex:'note/ChangeIndex',
    postLabel:'/note/label',
    putLabel:'/note/label',
    deleteLabel:'/note/label'
  },
  attendance:{
    get:'/attendance',
    getExcel: '/attendance/GetExcel',
    getExcelAll: '/attendance/GetAllExcelData',
    getSummaryReport: '/attendance/GetSummaryReport',
    delete: '/attendance',
    post: '/attendance',
    put: '/attendance',
    getUsers: '/attendance/getUsers'
  },
  project:{
    get: '/projects',
    post: '/projects',
    delete: '/projects',
  },
  ccData:{
    get: '/accTransactions',
    getFromProvider: '/accTransactions/GetFromService',
    assignGroup: '/AccExpense/AssignGroup',
    removeGroup: '/AccExpense/RemoveGroup',
    removeFromGroup: '/AccExpense/RemoveFromGroup',
    assignNewGroup: '/AccExpense/AssignNewGroup',
    assignProject: '/AccExpense/AssignProject',
    toggleTaxStatus: '/AccExpense/ChangeTaxStatus',
    download: '/accTransactions/download'
  }
};

export { API };
