const actions = {
    CHANGE_ID: 'CHANGE_ID',

    changeId: data => {
      return {
        type: actions.CHANGE_ID,
        data,
      };
    },
   
  };
  
  export default actions;
  