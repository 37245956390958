import { combineReducers } from 'redux';
import themeUsersReducer from './themeUsers/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
//import Profile from './profile/reducers';
// import { readMessageReducer } from './message/reducers';
// import { readNotificationReducer } from './notification/reducers';
// import { teamReducer } from './team/reducers';
// import { userReducer, userGroupReducer } from './users/reducers';
// import { sellersReducer } from './sellers/reducers';
// import { headerSearchReducer } from './headerSearch/reducers';
// import orderReducer from './orders/reducers';
// import galleryReducer from './gallary/reducers';
// import chartContentReducer from './chartContent/reducers';
// import { emailReducer, SingleEmailReducer } from './email/reducers';
// import { productReducer, SingleProductReducer } from './product/reducers';
// import { chatReducer, SingleChatReducer, groupChatReducer, SingleChatGroupReducer } from './chat/reducers';
// import { projectReducer, SingleProjectReducer } from './project/reducers';
// import cartData from './cart/reducers';
// import Todo from './todo/reducers';
 import Note from './note/reducers';
// import Task from './task/reducers';
// import kanbanBoardReducer from './kanban/reducers';
// import Contact from './contact/reducers';
// import Calender from './calendar/reducers';
// import FileManager from './fileManager/reducers';
// import { axiosCrudReducer, axiosSingleCrudReducer } from './crud/axios/reducers';
import CurrentCompanyReducer from './currentCompany/reducers';
const rootReducers = combineReducers({
  themeUsers: themeUsersReducer,
  auth: authReducer,
  ChangeLayoutMode,
  Note,
  currentCompany: CurrentCompanyReducer,
});

export default rootReducers;
