import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button } from '../../components/buttons/buttons';
import { Document, Page, pdfjs } from 'react-pdf';
import { DataService } from '../../config/dataService/dataService';
import { API } from '../../config/api';
import { ErrorWrapper, MaintananceWrapper } from './style';
import { notification, Spin, Modal } from 'antd';
import NotFound from './404';
import SignatureCanvas from 'react-signature-canvas';
import { arrayMove } from 'react-sortable-hoc';
import styled from 'styled-components';
import Heading from '../../components/heading/heading';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const FillForm = () => {
  const [data, setData] = useState(null);
  const [values, setValues] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [submitted, setSubmitted] = useState(0);
  const [clientId, setClientId] = useState(0);
  const [formFieldValues, setFormFieldValues] = useState(null);
  const { t, i18n } = useTranslation(['Fill_Form']);
  let { token } = useParams();
  useEffect(() => {
    // const search = window.location.search;
    // const params = new URLSearchParams(search);
    // let token = params.get('form');

    async function fetchData() {
      setLoading(true);
      const res = await DataService.get(API.fillForm.getForm + '?token=' + token);
      if (res.data.success) {
        setFormFieldValues(res.data.data.formFieldValues['$values']);
        setClientId(res.data.data.clientId);
        setData(res.data.data.form);
        i18n.changeLanguage(res.data.data.form.language);
      } else if (res.data?.success === false && res.data?.data?.alreadySubmitted) {
        setSubmitted(2);
      } else {
        setNotFound(true);
      }
      setLoading(false);
    }
    fetchData();
  }, []);
  async function Submit() {
    var validation = true;
    values.forEach(element => {
      if (element.required === true && element.value === '') {
        openNotification({
          type: 'error',
          message: t('Field_Required'),
          description: t('Field_Requird_Description').replace("{0}", element.name).replace("{1}", element.page),
        });
        validation = false;
        return;
      }
    });
    if (validation) {
      setLoading(true);
      const res = await DataService.post(API.fillForm.postValues, { formFieldValue: values, clientId });
      setLoading(false);
      if (res.data.success) {
        setSubmitted(1);
      }
    }
  }
  async function Save() {
    setLoading(true);
    const res = await DataService.post(API.fillForm.saveValues,
      { formFieldValue: values.map(obj => ({ ...obj, clientId })), clientId });
    setLoading(false);
    openNotification({
      type: 'success',
      message: t('Save_Notification_Title'),
      description: t('Save_Notification_Description'),
    });
  }
  if (notFound) {
    return <NotFound />;
  }

  if (submitted === 1) {
    return <ThankYou t={t} />;
  }
  if (submitted === 2) {
    return <AlreadySubmitted t={t} />;
  }

  return (
    <>
      <Spin spinning={loading}>
        <PageHeader
          title={data?.name}
          buttons={[
            <div key="1" className="page-header-actions">
              <Button size="small" type="primary" onClick={() => Submit()}>
                <FeatherIcon icon="send" size={14} />
                {t('Submit')}
              </Button>
              <Button size="small" type="primary" onClick={() => Save()}>
                <FeatherIcon icon="save" size={14} />
                {t('Save')}
              </Button>

            </div>,
          ]}
        />
        <Main>
          <Row gutter={25}>
            <Col xs={24} sm={24} md={24} xl={24} style={{ textAlign: '-webkit-center' }}>
              <Cards headless>
                <div id="doc" style={{ border: '1px', maxWidth: '650px', boxShadow: '0 0 10px 0px black' }}>
                  {data && (
                    <DocumentViewer
                      data={data}
                      currentPage={currentPage}
                      values={values}
                      setValues={setValues}
                      setTotalPages={setTotalPages}
                      cliendId={clientId}
                      formFieldValues={formFieldValues}
                      t={t}
                    />
                  )}
                </div>
                <br />
                <br />
                <div dir="ltr">
                  {totalPages > 1 && (
                    <Button
                      disabled={currentPage == 1}
                      type="dashed"
                      onClick={() => {
                        setCurrentPage(currentPage - 1);
                      }}
                    >
                      <FeatherIcon icon="chevrons-left" size={14} />
                    </Button>
                  )}

                  <Button disabled>
                    {t('Page_Info')
                      .replace('{0}', currentPage)
                      .replace('{1}', totalPages)}
                  </Button>
                  {totalPages > 1 && (
                    <Button
                      type="dashed"
                      disabled={currentPage == totalPages || totalPages == null}
                      onClick={() => {
                        setCurrentPage(currentPage + 1);
                      }}
                    >
                      <FeatherIcon icon="chevrons-right" size={14} />
                    </Button>
                  )}
                </div>
              </Cards>
            </Col>
          </Row>
        </Main>
      </Spin>
    </>
  );
};

export default FillForm;

const DocumentViewer = ({ data, setTotalPages, currentPage, values, setValues, formFieldValues, t }) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [signatureItem, setSignatureItem] = useState(null);
  const sigCanvas = useRef({});
  const [signWidth, setSignWidth] = useState(0);
  const [signHeight, setSignHeight] = useState(0);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const clearSignature = () => sigCanvas.current.clear();
  const saveSignature = () => {
    var arr = [...values];
    var index = values.findIndex(function (o) {
      return o.formFieldId === signatureItem?.formFieldId;
    });
    var e = arr[index];
    e.value = sigCanvas.current.toDataURL('image/png');
    arr[index] = e;
    setValues(arr);
    sigCanvas.current.clear();
    setSignatureItem(null);
  };
  useEffect(() => {

    if (signatureItem != null) {
      setTimeout(function () {

        var divWidth = document.getElementById('sign')?.offsetWidth;
        var oWidth = signatureItem.width;
        var oHeight = signatureItem?.height + signatureItem?.fontSize;
        var cent = divWidth / oWidth;
        setSignWidth(divWidth);
        setSignHeight(oHeight * cent);
        var value = values.find((x) => x.formFieldId === signatureItem.formFieldId);
        console.log(value);
        sigCanvas.current.fromDataURL(value.value);
      }, 500);
    }
  }, [signatureItem])
  function onDocumentLoadSuccess({ numPages }) {
    setTotalPages(numPages);
    setPageLoaded(true);
    var array = data?.formFields['$values']?.map(function (i) {
      var val = formFieldValues?.find((x) => x.formFieldId === i.id)
      var defaultValue = i.defaultValue;
      if (val != null) {
        defaultValue = val.value;
      }
      if (defaultValue == "") {
        if (i.type === "date") {
          defaultValue = moment().format('YYYY-MM-DD')
        }
      }

      return {
        formFieldId: i.id,
        required: i.required,
        name: i.name,
        value: defaultValue,
        page: i.pageNo,
        height: i.height,
        readOnly: i.readOnly,
        width: i.width,
        x: i.x,
        y: i.y,
        signature: i.signature,
        pageNo: i.pageNo,
        rtl: i.rtl,
        fontSize: i.fontSize,
        type: i.type,
        pageHeight: i.pageHeight,
        pageWidth: i.pageWidth,
      };
    });
    setValues(array);
  }
  function onValueChange(evt) {
    var elementId = (evt.target || evt.srcElement).id;
    var array = [...values];
    var index = values.findIndex(function (o) {
      return o.formFieldId == elementId;
    });
    var item = array[index];
    item.value = evt.target.value;
    array[index] = item;
    setValues(array);
  }
  function onDocumentLoadError() {
    openNotification({ type: 'error', message: t('PDF_Load_Failed'), description: t('PDF_Load_Failed_Description') });
  }

  const PageLoadSuccess = () => {
    var d = document.getElementById('document');
    setHeight(d.offsetHeight);
    setWidth(d.offsetWidth);
  };
  var fields = values
    ?.filter(i => i.pageNo == currentPage)
    .map(function (i) {
      // var height1 = i.height + i.fontSize;
      // var x1 = i.rtl === true ? (i.x * width) + 13 : (i.x * width) - 17;
      // var y1 = i.y * height - (height1 - 2);
      var compHeight = ((i.height + i.fontSize) / i.pageHeight) * height;
      var compWidth = (i.width / i.pageWidth) * width;
      var x1 = (i.x / i.pageWidth) * width;
      var y1 = (i.y / i.pageHeight) * height;
      var padding = (15 / i.pageWidth) * width;
      var fontSize = (i.fontSize / i.pageWidth) * width;
      return (
        <>
          <div
            dir="ltr"
            key={i.formFieldId}
            id={i.formFieldId.toString()}
            style={{
              width: '0px',
              position: 'absolute',
              height: '0px',
              backgroundColor: 'blue',
              WebkitUserSelect: 'none',
              MozUserSelect: 'none',
              OUserSelect: 'none',
              msUserSelect: 'none',
              khtmlUserSelect: 'none',
              userSelect: 'none',
              top: y1,
              left: x1,
            }}
          >
            {i.signature === false ? (
              <input
                id={i.formFieldId}
                key={'input' + i.formFieldId}
                type={i.type}
                onChange={e => onValueChange(e)}
                value={i.value}
                className="unstyled"
                readOnly={i.readOnly}
                style={{
                  backgroundColor: i.readOnly ? 'unset' : 'lightyellow',
                  marginLeft: padding + 'px',
                  // marginLeft: i.rtl === true ? '-' + (i.width + 15) + 'px' : '15px',
                  // width: i.width + 'px',
                  // height: i.height + i.fontSize + 'px',
                  fontSize: fontSize + 'px',
                  verticalAlign: 'top',
                  border: i.readOnly ? 'unset' : 'dashed 0.5px black',
                  width: compWidth + 'px',
                  height: compHeight + 'px',
                  maxHeight: compHeight + 'px',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                  borderRadius: 0,
                  textAlign: i.rtl === true ? 'right' : 'left',
                }}
              ></input>
            ) : i.value != '' ? (
              <img
                key={i.formFieldId.toString() + 'img'}
                onClick={() => setSignatureItem(i)}
                src={i.value}
                alt="my signature"
                style={{
                  border: 'dashed 0.5px black',
                  marginLeft: padding + 'px',
                  // width: i.width + 'px',
                  // height: i.height + i.fontSize + 'px',
                  width: compWidth + 'px',
                  height: compHeight + 'px',
                  padding: '0',
                }}
              />
            ) : (
              <div
                key={i.formFieldId.toString() + 'div'}
                onClick={() => setSignatureItem(i)}
                style={{
                  backgroundColor: 'lightyellow',
                  border: 'solid',
                  marginLeft: padding + 'px',
                  // width: i.width + 'px',
                  // height: i.height + i.fontSize + 'px',
                  width: compWidth + 'px',
                  height: compHeight + 'px',
                  padding: '0',
                }}
              ></div>
            )}
          </div>
        </>
      );
    });
  var url = API_ENDPOINT.slice(0, -4);
  function getWidth() {
    return document.getElementById('sign')?.offsetWidth;
  }
  return (
    <>
      <Modal
        visible={signatureItem != null}
        title={t("Signature")}
        onOk={saveSignature}
        //  width={signatureItem?.width*2.5}
        onCancel={() => {
          sigCanvas.current.clear();
          setSignatureItem(null)
        }}
        footer={null}
      // bodyStyle={{paddingRight:0, paddingLeft:0}}
      >
        <div style={{ textAlign: '-webkit-center', width: '100%' }}>
          <div
            id="sign"
            style={{
              width: '100%',
              border: 'dashed 0.5px black',
            }}
          >
            <SignatureCanvas
              ref={sigCanvas}
              canvasProps={{
                width: signWidth,
                height: signHeight,
                className: '',
              }}
            />
          </div>
        </div>
        <br />

        <div style={{ textAlign: '-webkit-center' }}>
          <Button style={{ marginTop: '10px' }} key="submit" type="primary" onClick={saveSignature}>
            {t('Done')}
          </Button>

          <Button
            style={{ marginTop: '10px', marginLeft: '5px', marginRight: '5px' }}
            key="clear"
            type="primary"
            onClick={clearSignature}
          >
            {t('Clear')}
          </Button>

          <Button style={{ marginTop: '10px' }} key="cancel" type="primary" onClick={() => setSignatureItem(null)}>
            {t('Close')}
          </Button>
        </div>
      </Modal>
      <PDFDocumentWrapper id="document">
        <Document file={url + data.url} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
          <Page pageNumber={currentPage} width={width} onLoadSuccess={PageLoadSuccess} />
        </Document>
        {pageLoaded && fields}
      </PDFDocumentWrapper>
    </>
  );
};

const openNotification = options => {
  notification[options.type]({
    message: options.message,
    description: options.description,
    placement: 'bottomRight',
  });
};

const PDFDocumentWrapper = styled.div`
  position: relative;
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

const ThankYou = ({ t }) => {
  return (
    <Main>
      <ErrorWrapper>
        <img src={require(`../../static/img/pages/404.svg`)} alt="404" />
        <Heading as="h4">{t('Thank_You_Title')}</Heading>
        <p>{t('Thank_You_Message')}</p>
      </ErrorWrapper>
    </Main>
  );
};

const AlreadySubmitted = ({ t }) => {
  return (
    <Main>
      <MaintananceWrapper>
        <img src={require(`../../static/img/pages/maintenance.svg`)} alt="submitted" />
        <Heading as="h4">{t('Already_Submitted_Title')}</Heading>
        <p>{t('Already_Submitted_Message')}</p>
      </MaintananceWrapper>
    </Main>
  );
};
