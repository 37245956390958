import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';
const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const NotFound = lazy(() => import('../container/pages/404'));
const RedirectToHome = () => {
  return <Redirect to="/" />;
};
const RedirectTo404 = () => {
  return <Redirect to="/404" />;
};

const FrontendRoutes = () => {
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >        

        <Route exact path="/forgotPassword" component={ForgotPass} />
        {/* <Route exact path="/register" component={SignUp} /> */}
        <Route exact path="/admin" component={RedirectToHome}/>
        <Route exact path="/404" component={NotFound}/>
        <Route exact path="/admin/*" component={RedirectToHome}/>
        <Route exact path="/" component={Login} />
        {/* <Route exact path='/*' component={RedirectTo404} /> */}

      </Suspense>
    </Switch>
  );
};

export default AuthLayout(FrontendRoutes);
