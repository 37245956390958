import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { API } from '../../config/api';
import { setItem, removeItem } from '../../utility/localStorageControl';
//import { useHistory } from 'react-router-dom';

const { changeId } = actions;

const ChangeCompanyId = values => {
  return async dispatch => {
    localStorage.setItem('companyId', values.id);
    return dispatch(
      changeId({ id: values.id}),
    );
  };
};
const ClearCompanyId = ()=>{
  return async dispatch => {
    return dispatch(
      changeId({id: 0}),
    );
  };
}

export { ChangeCompanyId, ClearCompanyId };
