import Cookies from 'js-cookie';
import actions from './actions';

import { setItem, removeItem } from '../../utility/localStorageControl';
import { useHistory } from 'react-router-dom';
import { ClearCompanyId } from '../currentCompany/actionCreator';
const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = values => {
  return async dispatch => {
   // try {
        dispatch(loginBegin());   
        
        return dispatch(loginSuccess({login: true, admin:values.isAdmin, roles: values.roles}));
      
     // return dispatch(loginErr(res.data?.message));
    // } catch (err) {
    //   dispatch(loginErr(err));
    // }
  };
};

const logOut = () => {
  return async dispatch => {
   // try {
      dispatch(logoutBegin());
      localStorage.removeItem('logedIn');
      localStorage.removeItem('role');
      localStorage.removeItem('user');
      localStorage.removeItem('admin')
      localStorage.removeItem('companies')
      localStorage.removeItem('companyId');
      removeItem('access_token');
      dispatch(logoutSuccess(null));
      dispatch(ClearCompanyId());
     // history.push('/admin');
    // } catch (err) {
    //   dispatch(logoutErr(err));
    // }
  };
};

export { login, logOut };
