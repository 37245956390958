import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
//import DeletedForms from '../../container/pages/DeletedForms';

// const NotFound = lazy(() => import('../../container/pages/404'));
// const Support = lazy(() => import('../../container/pages/support'));
// const Maintenance = lazy(() => import('../../container/pages/Maintenance'));
// const Pricing = lazy(() => import('../../container/pages/PricingTable'));
// const Faq = lazy(() => import('../../container/pages/Faq'));
// const Search = lazy(() => import('../../container/pages/SearchResult'));
// const ComingSoon = lazy(() => import('../../container/pages/ComingSoon'));
//const BlankPage = lazy(() => import('../../container/pages/BlankPage'));
const NewForm = lazy(() => import('../../container/pages/NewForm'));
const Forms = lazy(() => import('../../container/pages/Forms'));
const DeletedForms = lazy(() => import('../../container/pages/DeletedForms'));

const Companies = lazy(() => import('../../container/pages/Companies'));
const Projects = lazy(() => import('../../container/pages/Projects'));
const CCData = lazy(() => import('../../container/pages/accounting'));

const Users = lazy(() => import('../../container/pages/UsersList'));
const Attendance = lazy(() => import('../../container/pages/AttendanceList'));
const Note = lazy(() => import('../../container/note/Note'));


// const Wizards = lazy(() => import('../../container/pages/wizards/Wizards'));
// const Settings = lazy(() => import('../../container/profile/settings/Settings'));
// const KnowledgeBase = lazy(() => import('../../container/pages/knowledgeBase/Index'));
// const AllArticle = lazy(() => import('../../container/pages/knowledgeBase/AllArticle'));
// const KnowledgeSingle = lazy(() => import('../../container/pages/knowledgeBase/SingleKnowledge'));
// const ChangeLog = lazy(() => import('../../container/pages/ChangeLog'));

const PagesRoute = () => {
  const { path } = useRouteMatch();
  const { admin, roles, currentCompanyId } = useSelector(state => {
    return {
      admin: state.auth.admin,
      roles: state.auth.roles,
      currentCompanyId: state.currentCompany?.id,
    };
  });
  const currentCompanyRole = roles.filter(x => x.companyId === currentCompanyId).map(x => x.role);
  return (
    <Switch>
      {(admin || currentCompanyRole?.includes(4) || currentCompanyRole?.includes(5) || currentCompanyRole?.includes(6)) &&
        <Route path={`${path}/Attendance`} component={Attendance} />
      }

      {(admin || currentCompanyRole?.includes(2) || currentCompanyRole?.includes(3) || currentCompanyRole?.includes(7)) &&
        <Switch>
          <Route path={`${path}/newForm`} component={NewForm} />
          <Route path={`${path}/Forms/trash`} component={DeletedForms} />
          <Route path={`${path}/Forms`} component={Forms} />
          <Route path={`${path}/note`} component={Note} />
          {(currentCompanyRole.includes(7)) &&
            <Switch>
              <Route path={`${path}/CCData`} component={CCData} />
              <Route path={`${path}/Projects`} component={Projects} />
            </Switch>
          }
          {admin &&
            <Switch>
              <Route path={`${path}/Companies`} component={Companies} />
              <Route path={`${path}/Users`} component={Users} />
              <Route path={`${path}/CCData`} component={CCData} />
              <Route path={`${path}/Projects`} component={Projects} />
            </Switch>
          }

        </Switch>
      }

    </Switch>
  );
};

export default PagesRoute;
