import React from 'react';
import { ErrorWrapper } from './style';
import { NavLink } from 'react-router-dom';
import { Main } from '../styled';
import Heading from '../../components/heading/heading';
import { Button } from '../../components/buttons/buttons';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t, i18n } = useTranslation(['Not_Found']);
  return (
    <Main>
      <ErrorWrapper>
        <img src={require(`../../static/img/pages/404.svg`)} alt="404" />
        <Heading className="error-text" as="h3">
          404
        </Heading>
        <p>{t('Description')}</p>
        <NavLink to="/admin">
          <Button size="default" type="primary" to="/">
            {t('Return_Home')}
          </Button>
        </NavLink>
      </ErrorWrapper>
    </Main>
  );
};

export default NotFound;
